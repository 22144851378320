.bike-garage-container {
  padding: 20px;
  margin: auto;
  max-width: 1200px; /* Ajusta este valor según tus necesidades */
  background-color: #f5f5f5; /* Color de fondo suave */
}

/* Estilo para el título principal */
.bike-garage-container h4 {
  text-align: center;
  margin-bottom: 20px;
  color: #333; /* Color oscuro para el texto */
}

/* Estilo para los botones */
.bike-garage-container button {
  margin-bottom: 20px;
}

/* Estilo para las tarjetas */
.bike-garage-container .MuiCard-root {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease-in-out;
}

/* Efecto al pasar el mouse sobre las tarjetas */
.bike-garage-container .MuiCard-root:hover {
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
}

/* Estilo para imágenes de las tarjetas */
.bike-garage-container .MuiCardMedia-root {
  height: 200px; /* Ajusta la altura según tus necesidades */
  object-fit: cover;
}

/* Estilo para el texto dentro de las tarjetas */
.bike-garage-container .MuiCardContent-root {
  text-align: center;
}

/* Responsividad para pantallas pequeñas */
@media (max-width: 768px) {
  .bike-garage-container {
    padding: 10px;
  }
}
