
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  padding-top: 55px;
  padding-bottom: 80px;
}

/* Scrollbar for WebKit browsers (e.g., Chrome, Safari) */
::-webkit-scrollbar {
  width: 0px; /* width of the scrollbar */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* background of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* color of the scrollbar handle */
  border-radius: 10px; /* make the scrollbar handle rounded */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* darker color on hover */
}

/* Firefox scrollbar customization */
* {
  scrollbar-width: thin; /* Thin scrollbar for Firefox */
  scrollbar-color: #888 #f1f1f1; /* Scrollbar handle color, track color */
}
