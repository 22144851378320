/* Navbar.css */

/* Establece un tamaño máximo para el Navbar */
.AppBar-root {
  max-height: 64px;
}

/* Ajusta el tamaño del logo */
.Logo {
  max-height: 50px; /* Ajusta según sea necesario */
}

/* Agrega un espacio entre el logo y el menú en el Navbar */
div.spacer {
  flex-grow: 1;
}

/* Estilo para el menú de celular */
.navbar-mobile {
  display: none; /* Oculta el menú por defecto en pantallas grandes */
}

/* Estilo para la barra de herramientas */
.Toolbar-root {
  display: flex;
  justify-content: space-between;
}

/* Estilo para la sección de navegación */
.navbar-navigation {
  display: flex;
  align-items: center;
}

/* Estilo para los botones de navegación */
.navbar-button {
  margin-right: 10px; /* Ajusta el espaciado entre los botones según sea necesario */
}

/* Estilo para el menú de celular en pantallas pequeñas */
@media (max-width: 600px) {
  .navbar-desktop {
    display: none; /* Oculta la navegación en pantallas pequeñas */
  }

  .navbar-mobile {
    display: flex; /* Muestra el menú de celular en pantallas pequeñas */
    align-items: center;
  }
}
